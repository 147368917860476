.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #F8F8F3;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: box-shadow 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.header.floating {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header__content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.header__actions {
  display: flex;
  align-items: center;
}

.header__nav-list {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.header__nav-item {
  display: inline-block;
}

.header__nav-link {
  color: #333;
  text-decoration: none;
  font-size: 28px;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  padding: 0;
}

.header__nav-link:hover {
  color: #007bff;
}

.logo {
  max-width: 160px;
  margin-bottom: 0;
  transition: max-width 0.3s ease;
}

.logo-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.logo img {
  width: 140%;
  height: auto;
}

.passarinho01 {
  max-width: 200px;
  margin-bottom: 0;
  position: absolute;
  right: 0;
  bottom: -60px;
}

.passarinho01 img {
  width: 100%;
  height: auto;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.language-selector {
  display: flex;
  gap: 5px;
}

.language-selector button {
  background: none;
  border: 1px solid #333;
  padding: 3px 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.language-selector button:hover,
.language-selector button.active {
  background-color: #333;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 5px 10px;
  }

  .header__content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    order: 2;
  }

  .header__nav {
    display: none;
    width: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #f8f8f8;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .header__nav.open {
    display: block;
  }

  .header__nav-list {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .header__nav-link {
    font-size: 18px;
  }

  .header__actions {
    position: absolute;
    top: 15px;
    right: 15px;
    order: 1;
  }

  .menu-icon {
    display: block;
    background-color: #f8f8f8;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .logo {
    max-width: 120px;
    margin-bottom: 0;
  }

  .language-selector {
    order: -1;
    width: auto;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  .passarinho01 {
    display: none;
  }
}