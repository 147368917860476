@font-face {
  font-family: 'Berose Demo';
  src: url('../fonts/BEROSE DEMO.TTF') format('truetype');
  font-display: swap;
}

html, body {
  font-family: 'Berose Demo', Arial, sans-serif;
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

body:not(.home-page) {
  background-image: url('../assets/optimized/backgroud-image.webp');
  background-size: auto;
  background-repeat: repeat;
  background-position: top left;
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: lighten;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
}

.app-content {
  flex: 1;
  padding-bottom: 60px;
  padding-top: 80px;
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.whatsapp-float:hover {
  background-color: #128C7E;
}

@media screen and (max-width: 768px) {
  .app-content {
    padding-top: 60px;
  }

  .whatsapp-float {
    width: 50px;
    height: 50px;
    bottom: 30px;
    right: 20px;
    font-size: 25px;
  }
}
