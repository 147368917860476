.footer {
  background-color: #97A478;
  color: #000;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.footer__left {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.footer__center {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.footer__right {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.footer__text {
  margin: 3px 0;
  font-size: 14px;
}

.footer__social-link {
  color: #000;
  transition: color 0.3s;
  margin-left: 15px;
}

.footer__social-link:hover {
  color: #007bff;
}

.footer__logo {
  width: 130px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 10px;
  }

  .footer__left, .footer__center, .footer__right {
    padding: 5px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer__text {
    font-size: 12px;
  }

  .footer__logo {
    width: 130px;
  }

  .footer__social-link {
    margin: 0 8px;
  }
}